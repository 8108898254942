import './bootstrap';

// Import our custom CSS
import '../scss/app.scss'
import { createApp, h } from 'vue';
import { createInertiaApp, Link  } from '@inertiajs/inertia-vue3';
import { InertiaProgress } from '@inertiajs/progress';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import Notifications from '@kyvg/vue3-notification'
import moment from 'moment';
import 'moment/dist/locale/fr';
moment.locale('fr');


const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, app, props, plugin }) {
        return createApp({ render: () => h(app, props) })
            .use(plugin)
            .use(Notifications)
            .use(ZiggyVue, Ziggy)
            .mixin(moment)
            .component('InertiaLink', Link)
            .mixin({
                methods: {
                    __(key, replace = {}) {
                        // On recupère la traduction de la clé
                        // Si la traduction existe alors l'utliser sinon utiliser la clé elle-meme
                        var translation = this.$page.props.language[key] ? this.$page.props.language[key] : key;
                        Object.keys(replace).forEach(function (key) {
                            // Remplace toutes les occurences correspondantes dans la chaine de traduction
                            translation = translation.replace(':' + key, replace[key])
                        });
                        console.log([key, translation]);
                        return translation
                    },
                },
            })
            .mount(el);
    },
});

InertiaProgress.init({ color: '#4B5563' });
